/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import classSetter from 'classnames';
import PropTypes from 'prop-types';

import { useUser } from 'context/UserContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { loadMoreValidator, mappingExtension } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import ModalWrapper from 'oldComponents/v2/ModalWrapper.jsx';

import './Modal.scss';

const Interfaces = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.bool
  ]),
  eventOnClickClose: PropTypes.func,
  withCloseIcon: PropTypes.bool,
  dataCyModal: PropTypes.string,
  withFileBtn: PropTypes.object,
  withDownloadFileBtn: PropTypes.object,
  withPrimaryBtn: PropTypes.object,
  withSecondaryBtn: PropTypes.object,
  withTertiaryBtn: PropTypes.object,
  withLeftBtn: PropTypes.object,
  useBorder: PropTypes.bool,
  headerIcon: PropTypes.object,
  headerClass: PropTypes.string,
  footerClass: PropTypes.string,
  withFooter: PropTypes.bool,
  contentClass: PropTypes.string,
  maxHeight: PropTypes.number,
  withContentMarginBottom: PropTypes.bool,
  decreaseHeight: PropTypes.number,
  withSteps: PropTypes.object,
  isUseOptionalHeader: PropTypes.bool,
  withCloseAnimation: PropTypes.bool,
  useMouseDownClose: PropTypes.bool
};
const DefaultValue = {
  className: '',
  title: '',
  description: '',
  eventOnClickClose: () => null,
  useMouseDownClose: false,
  withCloseIcon: true,
  dataCyModal: '',
  withPrimaryBtn: {
    title: '',
    onClick: () => null,
    disabled: false,
    dataCy: '',
    isLoading: false
  },
  withFileBtn: {},
  withSecondaryBtn: {},
  withTertiaryBtn: {},
  withLeftBtn: {},
  useBorder: false,
  withFooter: true,
  maxHeight: 800,
  contentClass: '',
  contentWrapperClass: '',
  withContentMarginBottom: true,
  decreaseHeight: 154,
  withSteps: {},
  isUseOptionalHeader: false,
  withCloseAnimation: true
};

function Modal({
  className,
  title,
  description,
  children,
  eventOnClickClose,
  withCloseIcon,
  headerClass,
  footerClass,
  dataCyModal,
  withFileBtn,
  withDownloadFileBtn,
  withPrimaryBtn,
  withSecondaryBtn,
  withTertiaryBtn,
  withLeftBtn,
  useBorder,
  headerIcon,
  maxHeight,
  minWidth,
  contentClass,
  withFooter,
  contentWrapperClass,
  isScrollBottom,
  withContentMarginBottom,
  decreaseHeight,
  withSteps,
  useStopPropagationOnScroll,
  isUseOptionalHeader,
  withCloseAnimation,
  useMouseDownClose
}) {
  const { config } = useUser() || {};
  const modalContent = useRef();
  const currentHeight = modalContent.current?.getBoundingClientRect?.()?.height;
  const overflowContent = useRef();
  const [border, setBorder] = useState({
    top: false,
    bot: true
  });
  const [animationModal, setAnimationModal] = useState('open-animation-modal');
  const topPart = useRef();
  const botPart = useRef();
  const SetIntersectionObserver = (ref, stateKey) =>
    useIntersectionObserver({
      target: ref,
      onIntersect: (entry) =>
        !(currentHeight < maxHeight) &&
        setBorder((prev) => {
          return { ...prev, [stateKey]: !entry.isIntersecting };
        }),
      threshold: 1
    });
  SetIntersectionObserver(topPart, 'top');
  SetIntersectionObserver(botPart, 'bot');

  const modalClass = {
    'modal-content': true
  };

  const onScroll = (e) => {
    useStopPropagationOnScroll && e.stopPropagation();
    const target = e.target;
    loadMoreValidator(target, 20, () => {
      isScrollBottom && isScrollBottom(true);
    });
  };

  const containerStyle = {
    minWidth: minWidth ? minWidth + 'px' : '350px',
    ...(maxHeight && { maxHeight: maxHeight + 'px' })
  };

  const eventClose = () => {
    if (eventOnClickClose !== undefined) {
      withCloseAnimation && setAnimationModal('close-animation-modal');
      setTimeout(() => {
        eventOnClickClose();
      }, 200);
    } else {
      setAnimationModal('');
    }
  };

  const closeIconProps = {
    iconName: 'icon-clear',
    size: '24',
    fillColor: 'var(--n-600)',
    dataCy: 'close-modal',
    customClass: 'cursor-pointer',
    ...(useMouseDownClose
      ? { onMouseDown: eventClose }
      : { onClick: eventClose })
  };

  useEffect(() => {
    if (modalContent.current) {
      if (currentHeight < maxHeight) {
        overflowContent.current.className = 'overflow-hidden';
        setBorder({
          top: false,
          bot: false
        });
      }
    }
  }, []);

  return (
    <ModalWrapper
      dataCyModal={dataCyModal}
      closeFunctionWithAnimation={() => eventClose()}
      className={animationModal}
      title={title}
    >
      <div
        ref={modalContent}
        className={`${classSetter(modalClass, className)} ${animationModal}
        }`}
        data-cy="modal"
        id="shared-modal-content"
        style={containerStyle}
      >
        <div
          className={`${
            headerClass ? headerClass : ''
          } header d-block height-auto mb-[0px]`}
        >
          <div
            className={`flex justify-between px-[24px] py-[24px] ${
              useBorder && maxHeight && border.top
                ? 'border-header-modal mb-[4px]'
                : 'mb-[4px]'
            }`}
          >
            <div className="modal-title flex" data-cy="modal-title">
              {headerIcon?.name && (
                <SVGIcon
                  iconName={headerIcon.name}
                  size={headerIcon?.iconSize || '24'}
                  fillColor={headerIcon?.color}
                  customClass={`mr-[16px] ${headerIcon?.customClass}`}
                  onClick={() => headerIcon?.onClick && headerIcon?.onClick()}
                />
              )}
              <div className="typography-h600 text-n-900">
                {getObjectiveLocale(title)}
                {isUseOptionalHeader && (
                  <span className="typography-h600 text-n-600">
                    {' '}
                    ({getObjectiveLocale('optional')})
                  </span>
                )}
              </div>
            </div>
            {withCloseIcon && <SVGIcon {...closeIconProps} />}
          </div>
        </div>
        <div
          ref={overflowContent}
          className={contentWrapperClass}
          onScroll={onScroll}
        >
          <div ref={topPart} className="h-[1px]" />
          {description && (
            <div
              className={`modal-desc px-[24px] text-n-800 ${
                useBorder ? 'my-[16px]' : 'mb-[16px]'
              }`}
              data-cy="modal-description"
            >
              {getObjectiveLocale(description)}
            </div>
          )}
          {children && (
            <div
              className={`px-[24px] ${
                withFooter ? '' : withContentMarginBottom ? 'mb-24' : ''
              } ${contentClass ? contentClass : ''}`}
              style={{
                ...(maxHeight > 153
                  ? { maxHeight: maxHeight - decreaseHeight + 'px' }
                  : {})
              }}
            >
              {children}
            </div>
          )}
          <div ref={botPart} className="mb-[1px]" />
        </div>
        {withFooter && (
          <div
            className={`${footerClass ? footerClass : ''} ${
              useBorder && maxHeight && border.bot
                ? 'border-footer-modal mt-[4px]'
                : 'mt-[4px]'
            } footer-shared-modal`}
          >
            {withLeftBtn?.title && (
              <Button.Tertiary
                id={withLeftBtn.dataCy}
                onClick={withLeftBtn.onClick}
                customClass="flex flex-row gap-[4px]"
              >
                {withLeftBtn?.iconName && (
                  <SVGIcon
                    iconName={withLeftBtn.iconName}
                    size={24}
                    fillColor="var(--base-600)"
                  />
                )}
                {getObjectiveLocale(withLeftBtn.title)}
              </Button.Tertiary>
            )}
            {withFileBtn && (
              <div className="file-button flex items-center">
                {withFileBtn?.title && (
                  <Button.Tertiary
                    id="btn-attachment"
                    customClass="relative"
                    disabled={
                      withFileBtn.isLoading ? true : withFileBtn.disabled
                    }
                  >
                    {getObjectiveLocale(withFileBtn.title)}
                    <input
                      className="z-index-50 absolute h-full w-full cursor-pointer opacity-0"
                      id="attachment"
                      type="file"
                      accept={
                        withFileBtn.accept
                          ? withFileBtn.accept
                          : config?.allowedAttachmentExtensions
                          ? mappingExtension(
                              config?.allowedAttachmentExtensions
                            )
                          : []
                      }
                      multiple
                      onClick={(e) => (e.target.value = null)}
                      onChange={(e) => withFileBtn.onClick(e)}
                      data-cy={withFileBtn.dataCy}
                      title={' '}
                      disabled={withFileBtn.disabled}
                    />
                  </Button.Tertiary>
                )}

                {withSteps?.currentStepNumber && withSteps?.lastStepNumber && (
                  <p className="typography-button text-n-600">
                    Steps {withSteps.currentStepNumber} of{' '}
                    {withSteps.lastStepNumber}
                  </p>
                )}
              </div>
            )}
            {withDownloadFileBtn && (
              <a
                href={withDownloadFileBtn.href}
                download={withDownloadFileBtn.fileName}
              >
                <Button.Tertiary
                  id="btn-download-file"
                  disabled={
                    withDownloadFileBtn.isLoading
                      ? true
                      : withDownloadFileBtn.disabled
                  }
                >
                  {getObjectiveLocale(withDownloadFileBtn.title)}
                </Button.Tertiary>
              </a>
            )}
            <div className="flex items-center">
              {withSecondaryBtn?.showSkeleton ? (
                <Shimmer width={80} height={32} />
              ) : (
                withSecondaryBtn?.title && (
                  <Button.Secondary
                    id={withSecondaryBtn.dataCy}
                    disabled={
                      withSecondaryBtn.isLoading
                        ? true
                        : withSecondaryBtn.disabled
                    }
                    bgColor="no-border bg-n-100"
                    onClick={withSecondaryBtn.onClick}
                  >
                    {getObjectiveLocale(withSecondaryBtn.title)}
                  </Button.Secondary>
                )
              )}
              {withTertiaryBtn?.title && (
                <Button.Tertiary
                  id={withTertiaryBtn.dataCy}
                  onClick={withTertiaryBtn.onClick}
                >
                  {getObjectiveLocale(withTertiaryBtn.title)}
                </Button.Tertiary>
              )}
              {withPrimaryBtn?.showSkeleton ? (
                <Shimmer width={120} height={32} customClass="ml-[8px]" />
              ) : (
                withPrimaryBtn?.title && (
                  <TooltipContainer
                    show={withPrimaryBtn.tooltipText}
                    text={getObjectiveLocale(withPrimaryBtn.tooltipText)}
                  >
                    <Button
                      id={withPrimaryBtn.dataCy}
                      customClass="ml-[8px] flex items-center justify-center"
                      onClick={withPrimaryBtn.onClick}
                      disabled={
                        withPrimaryBtn.isLoading
                          ? true
                          : withPrimaryBtn.disabled
                      }
                      bgColor={withPrimaryBtn.bgColor}
                      variant={withPrimaryBtn.danger ? 'danger' : 'primary'}
                      onMouseDown={
                        withPrimaryBtn?.onMouseDown &&
                        withPrimaryBtn.onMouseDown
                      }
                    >
                      {withPrimaryBtn.isLoading && (
                        <LoadingComponent
                          size={20}
                          hasText={false}
                          className="mr-[8px]"
                        />
                      )}
                      {getObjectiveLocale(withPrimaryBtn.title)}
                    </Button>
                  </TooltipContainer>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}
Modal.propTypes = Interfaces;
Modal.defaultProps = DefaultValue;

const ModalPortal = (props) => {
  const sharedModal = document.querySelectorAll(`[id^="shared-modal"]`)[0];

  return ReactDOM.createPortal(<Modal {...props} />, sharedModal);
};

export default ModalPortal;
