"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCSSVariable = exports.getTailwindBorderColorClass = exports.getTailwindBgColorClass = exports.getTailwindTextColorClass = exports.getTailwindColorClass = exports.colorsHex = void 0;
const HelperUtils_1 = require("./HelperUtils");
exports.colorsHex = {
    '#5417CF': 'v',
    '#DB2777': 'p',
    '#E11D48': 'c',
    '#D97706': 'a',
    '#EA580C': 'o',
    '#65A30D': 'l',
    '#059669': 'e',
    '#0D9488': 't',
    '#0891B2': 'cy',
    '#0284C7': 'lb',
    '#4F46E5': 'i',
    '#C026D3': 'f'
};
const colorDict = {
    EC4899: 'p',
    F43F5E: 'c',
    F59E0B: 'a',
    '84CC16': 'l',
    '14B8A6': 't',
    '0EA5E9': 'lb',
    '6366F1': 'i'
};
const getTailwindColorClass = ({ hexCode, classPrefix, shade = 700 }) => {
    let alias = 'n';
    if (hexCode && colorDict[hexCode]) {
        alias = colorDict[hexCode];
    }
    return `${classPrefix}-${alias}-${shade}`;
};
exports.getTailwindColorClass = getTailwindColorClass;
const getTailwindTextColorClass = ({ hexCode, shade }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'text', shade });
};
exports.getTailwindTextColorClass = getTailwindTextColorClass;
const getTailwindBgColorClass = ({ hexCode, shade = 100 }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'bg', shade });
};
exports.getTailwindBgColorClass = getTailwindBgColorClass;
const getTailwindBorderColorClass = ({ hexCode, shade = 200 }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'border', shade });
};
exports.getTailwindBorderColorClass = getTailwindBorderColorClass;
const getCSSVariable = ({ hexCode, shade = 700 }) => {
    const alias = hexCode ? colorDict[hexCode] : 'n';
    const _shade = alias === 'n' ? 800 : shade;
    return (0, HelperUtils_1.getCssVariableValue)(`--${alias}-${_shade}`);
};
exports.getCSSVariable = getCSSVariable;
