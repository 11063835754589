import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';

import activityObjectiveKeys from 'consts/queryKeys/activityObjectiveKeys';
import objectiveKeys from 'consts/queryKeys/objectiveKeys';
import talentComparisonKeys from 'consts/queryKeys/talentComparisonKeys';

//CREATE CONTEXT
const RefetchQueryContext = React.createContext();

//PROVIDER
function RefetchQueryProvider(props) {
  const queryClient = useQueryClient();
  return <RefetchQueryContext.Provider value={{ queryClient }} {...props} />;
}

function useRefetchQuery() {
  const { queryClient } = React.useContext(RefetchQueryContext);
  const params = useParams();

  const invalidateQueries = async (queryKey, options) => {
    await queryClient.invalidateQueries(queryKey, options);
  };

  const filterObjectiveFromDeletedId = (list, deleteChildren, objectiveId) => {
    if (!Array.isArray(list)) return list;
    if (deleteChildren) {
      return list?.filter(
        (data) =>
          data.id !== objectiveId &&
          !data.parents.some((parent) => parent.id === objectiveId)
      );
    }
    const filteredData = list?.filter((data) => data.id != objectiveId);

    filteredData?.map((item) => {
      const parentIds = item?.parents?.map((parent) => parent?.id);
      if (parentIds?.includes(objectiveId))
        queryClient.setQueryData(
          ['objective', parseInt(item?.id)],
          (currentData) => {
            const dataClone = currentData.data
              ? { ...currentData.data }
              : { ...currentData };
            if (dataClone.parent?.id == objectiveId) {
              dataClone.parent = null;
            }
            dataClone.parents = dataClone.parents.filter(
              (par) => par.id != objectiveId
            );
            return { ...currentData, data: dataClone };
          }
        );
    });

    return filteredData;
  };

  const updateQueryAfterDeleted = (
    mainQueryKey,
    objectiveId,
    deleteChildren
  ) => {
    const currentQueries = queryClient?.getQueriesData(mainQueryKey);

    for (const query of currentQueries) {
      const queryKey = query[0];
      queryClient.setQueryData(queryKey, (currentData) => {
        if (!currentData || (!currentData?.pages && !currentData?.data))
          return currentData;
        let pages = currentData?.pages;

        if (!pages) {
          const data = filterObjectiveFromDeletedId(
            currentData.data,
            deleteChildren,
            objectiveId
          );
          return { ...currentData, data };
        }
        pages = pages?.map((page) => {
          const data = filterObjectiveFromDeletedId(
            page.data,
            deleteChildren,
            objectiveId
          );

          return { ...page, data };
        });
        return { ...currentData, pages };
      });
    }
    queryClient.removeQueries(['objectives', objectiveId]);
  };

  const getMainQueryKeys = ({
    usingPageParams,
    page,
    parentIds,
    listQueryKey
  }) => {
    if (parentIds?.length > 0) {
      return parentIds?.map((parentId) => ['sub-objectives', parentId]);
    }

    if (listQueryKey) {
      return [listQueryKey];
    }

    const defaultMainKey = usingPageParams
      ? ['objectives', page]
      : ['objectives'];

    return [defaultMainKey];
  };

  const refetchAfterDeleted = ({
    objectiveId,
    parentIds,
    page,
    listQueryKey,
    deleteChildren
  }) => {
    let usingPageParams = false;
    if (parentIds?.length === 0 && !listQueryKey) {
      usingPageParams = queryClient?.getQueriesData(['objectives', page]);
    }

    const mainQueryKeys = getMainQueryKeys({
      usingPageParams,
      page,
      parentIds,
      listQueryKey
    });

    mainQueryKeys?.map((mainQueryKey) => {
      updateQueryAfterDeleted(mainQueryKey, objectiveId, deleteChildren);
    });

    if (page == 'tree' && params?.parentId != objectiveId) {
      queryClient.setQueryData(
        ['objective', parseInt(params.parentId)],
        (currentData) => {
          const dataClone = currentData.data
            ? { ...currentData.data }
            : { ...currentData };
          if (dataClone.parent?.id == objectiveId) {
            dataClone.parent = null;
          }
          dataClone.parents = dataClone.parents.filter(
            (par) => par.id != objectiveId
          );
          return { ...currentData, data: dataClone };
        }
      );
    }

    const childrenQuery = queryClient?.getQueriesData({
      queryKey: ['sub-objectives', objectiveId],
      exact: false
    });
    if (!deleteChildren) {
      refetchObjectives(mainQueryKeys);

      childrenQuery?.[0]?.[1]?.pages?.[0]?.data?.map((child) => {
        refetchQueries(['objective', child.id]);
        refetchRecursiveSubObjectives(child.id);
      });
    }

    recursivelyRefetchObjective(parentIds);
  };

  const refetchQueries = async (queryKey, options = {}) => {
    await queryClient.refetchQueries(queryKey, { active: true, ...options });
  };

  const removeQueries = (queryKey) => {
    queryClient.removeQueries(queryKey);
  };

  const refetchObjective = async (objectiveId) => {
    objectiveId = parseInt(objectiveId);
    await recursivelyRefetchObjective(objectiveId);

    // refetch overallProgress
    refetchQueries(['overallProgress']);
    removeQueries(activityObjectiveKeys.default);
  };

  const recursivelyRefetchObjective = async (objectiveId) => {
    const query = queryClient?.getQueryData(['objective', objectiveId]) || {};

    if (query) {
      await refetchQueries(['objective', objectiveId]);
      await refetchQueries(objectiveKeys.involvements(objectiveId));
      await refetchQueries(objectiveKeys.sharedInvolvements(objectiveId));
    }

    await refetchQueries(
      talentComparisonKeys.comparisonGoalAssesmentsDetail(objectiveId),
      { active: false }
    );

    const data = Object.prototype.hasOwnProperty.call(query, 'data')
      ? query.data
      : query;

    if (data?.parents?.length > 0) {
      const parentIds = data?.parents?.map(({ id }) => id);
      parentIds.map(
        async (id) =>
          id !== objectiveId && (await recursivelyRefetchObjective(id))
      );
    }

    if (location.pathname.includes('teams') && data?.teamId !== objectiveId) {
      await recursivelyRefetchObjective(data?.teamId);
    }
  };

  const refetchObjectives = (page) => {
    const query = queryClient?.getQueryData(['objectives', page]);
    if (query) {
      refetchQueries(['objectives', page]);
    } else {
      refetchQueries(['objectives']);
    }

    // refetch overallProgress
    refetchQueries(['overallProgress']);
  };

  const refetchAfterConvert = (objectiveId, forceRefetchObjective = false) => {
    let isTwoLevelSubGoal = false;
    const query = queryClient?.getQueryData(['sub-objectives', objectiveId]);
    if (query?.pages?.[0]?.data) {
      for (const child of query.pages[0].data) {
        const queryChild = queryClient?.getQueryData([
          'sub-objectives',
          child?.id
        ]);
        if (queryChild) {
          isTwoLevelSubGoal = true;
          break;
        }
      }
    }

    //will refetch one page, if:
    //1. has > 1 level sub child
    //2. parent only but has child
    //3. sub goal has project & user choose convert with subgoal
    if (isTwoLevelSubGoal || forceRefetchObjective) {
      refetchObjectives();
    }
    refetchRecursiveSubObjectives(objectiveId);
  };

  const refetchRecursiveSubObjectives = (objectiveId) => {
    const query = queryClient?.getQueryData(['sub-objectives', objectiveId]);
    if (query) {
      refetchQueries(['sub-objectives', objectiveId]);

      query?.pages?.[0]?.data?.map((child) => {
        refetchQueries(['objective', child.id]);
        refetchRecursiveSubObjectives(child.id);
      });
    }
  };

  const refetchSubObjectives = (objectiveId) => {
    const query = queryClient?.getQueryData(['sub-objectives', objectiveId]);
    if (query) {
      queryClient.refetchQueries(['sub-objectives', objectiveId]);
    }

    query?.data?.parents?.length > 0 &&
      query?.data?.parents?.map((parent) => refetchSubObjectives(parent?.id));
  };

  const invalidateTaskQueries = ({
    task,
    isSubTask = false,
    groupData = null,
    refetchActive = false
  }) => {
    if (!task) return;
    const taskData = isSubTask ? task?.parent : task;

    if (isSubTask && taskData) {
      invalidateQueries(['objective', taskData.id]);
    }

    // My Task:
    // invalidate for phase group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.phase?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate for priority group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.priority?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate for section group
    invalidateQueries(
      [
        'objectives',
        'mytasks',
        taskData?.section?.name?.toLowerCase() || 'task'
      ],
      { refetchActive }
    );

    // invalidate for project group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.parent?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate current group
    invalidateQueries(
      ['objectives', 'mytasks', groupData?.name?.toLowerCase()],
      { refetchActive }
    ),
      // Project Task:
      // invalidate for phase section group
      invalidateQueries(
        [
          'objectives',
          'mytasks',
          taskData?.phase?.name?.toLowerCase(),
          taskData?.section?.name?.toLowerCase() || 'task'
        ],
        { refetchActive }
      );

    // invalidate for priority section group
    invalidateQueries(
      [
        'objectives',
        'mytasks',
        taskData?.priority?.name?.toLowerCase(),
        taskData?.section?.name?.toLowerCase() || 'task'
      ],
      { refetchActive }
    );
  };

  return {
    refetchQueries,
    refetchObjective,
    refetchObjectives,
    refetchSubObjectives,
    invalidateQueries,
    invalidateTaskQueries,
    removeQueries,
    refetchAfterDeleted,
    refetchRecursiveSubObjectives,
    refetchAfterConvert,
    updateQueryAfterDeleted
  };
}

export { RefetchQueryProvider, useRefetchQuery };
